// ANCHOR React
import { useEffect, useRef } from 'react';

// ANCHOR Toast
import { toast } from 'react-hot-toast';

// ANCHOR Hooks
import { useOnlineStatus } from '@lyonph/react-hooks';

// ANCHOR Components
import { constant } from '@lyon/components/utils/constant';

export const OnlineTracker = constant(() => {
  const status = useOnlineStatus();

  const initial = useRef(true);
  const previous = useRef<string>();

  useEffect(() => {
    if (status) {
      if (!initial.current) {
        if (previous.current) {
          toast.dismiss(previous.current);
        }
        previous.current = toast.success('You are now online.');
      }
    } else {
      if (previous.current) {
        toast.dismiss(previous.current);
      }
      previous.current = toast.error('You are offline.');
    }
    initial.current = false;
  }, [status]);

  return null;
});
