// ANCHOR Graph State
import {
  useGraphNodeValue,
} from 'react-graph-state';
import {
  swr,
} from 'swr-graph-state';

// ANCHOR API
import { getUserProviderAccount } from '@lyon/utils/api/user';
import { IProviderResult } from '@lyon/utils/api/results/provider';

// ANCHOR Dependencies
import { authStateNode } from '../firebase/auth/auth-state-node';

export const userProviderAccountNode = swr<IProviderResult[] | null | undefined>({
  key: 'userProviderAccountNode',
  setup: (context) => {
    const user = context.get(authStateNode);

    return async () => {
      if (user) {
        return getUserProviderAccount({
          token: await user.getIdToken(),
          userId: user.uid,
        });
      }
      return user;
    };
  },
  freshAge: 0,
  revalidateOnFocus: true,
  revalidateOnVisibility: true,
  staleAge: 1000 * 60 * 60,
});

export function useUserProviderAccount() {
  return useGraphNodeValue(userProviderAccountNode.resource);
}
