interface IMixpanel {
  mixpanel: {
    cookie: {
      clear: () => void;
    }
  }
}

declare const window: Window & typeof globalThis & IMixpanel;

interface SegmentOpts {
  integrations?: any;
  anonymousId?: string;
  context?: Record<string, any>;
}

export const track = (
  name: string,
  traits: Record<string, any>,
  isAdmin: boolean | undefined,
  options?: SegmentOpts,
) => {
  const shouldTrack = typeof isAdmin === 'boolean' && !isAdmin;

  if (shouldTrack) {
    window.analytics.track(name, traits, options);
  }
};

export const reset = () => {
  window.analytics.ready(() => {
    window.mixpanel.cookie.clear();
  });
  window.analytics.reset();
};

export const identify = (
  id: string,
  traits: Record<string, any>,
  isAdmin: boolean | undefined,
) => {
  const shouldTrack = typeof isAdmin === 'boolean' && !isAdmin;

  if (shouldTrack) {
    window.analytics.identify(id, traits);
  }
};

export const group = (
  id: string,
  traits: Record<string, any>,
  isAdmin: boolean | undefined,
) => {
  const shouldTrack = typeof isAdmin === 'boolean' && !isAdmin;

  if (shouldTrack) {
    window.analytics.group(id, traits);
  }
};

export const page = (
  url: string,
  isAdmin: boolean | undefined,
) => {
  const shouldTrack = typeof isAdmin === 'boolean' && !isAdmin;

  if (shouldTrack) {
    window.analytics.page(url);
  }
};
