// ANCHOR React
import React, { ReactNode } from 'react';

// ANCHOR Base
import { BaseProvider, createTheme, darkThemePrimitives } from 'baseui';

// ANCHOR Theme
import { THEME } from '../utils/theme';

// ANCHOR Utils
import { useConstant } from '../utils/hooks/useConstant';

interface IProps {
  children?: ReactNode;
}

export function LyonThemeProvider({ children }: IProps) {
  const theme = useConstant(() => createTheme(darkThemePrimitives, THEME));

  return (
    <BaseProvider theme={theme}>
      { children }
    </BaseProvider>
  );
}
