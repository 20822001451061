export const MONO = {
  white: '#FFFFFF',
  mono100: '#FFFFFF',
  mono200: '#F6F6F6',
  mono300: '#EEEEEE',
  mono400: '#E2E2E2',
  mono500: '#CBCBCB',
  mono600: '#B5B5B5',
  mono700: '#757575',
  mono800: '#545454',
  mono900: '#333333',
  mono1000: '#000000',
  black: '#000000',
};
