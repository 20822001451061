export const RATING = {
  rating: '#5B36C9',
  rating50: '#F3F0FF',
  rating100: '#BBA8F5',
  rating200: '#8164D8',
  rating300: '#6341C8',
  rating400: '#5B36C9',
  rating500: '#4D3399',
  rating600: '#271F47',
  rating700: '#16141F',
};
