/* eslint-disable no-underscore-dangle */
// ANCHOR Segment
import * as snippet from '@segment/snippet';

export function renderSegmentSnipper(): string {
  const opts = {
    apiKey: process.env.SEGMENT_ANALYTICS_API_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}
