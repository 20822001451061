export function ucarecdn(uuid?: string): string {
  if (uuid) {
    return `https://ucarecdn.com/${uuid}/`;
  }

  return 'https://ucarecdn.com/b55ef792-691b-4afe-8205-9b7490074254/nothumbnail.svg';
}

export function ucarecdnOptimize(uuid?: string): string {
  if (uuid) {
    return `https://ucarecdn.com/${uuid}/-/preview/-/quality/smart/-/progressive/yes/`;
  }

  return 'https://ucarecdn.com/b55ef792-691b-4afe-8205-9b7490074254/nothumbnail.svg';
}

export function ucarecdnScaleCropOptimize(scale: string, uuid?: string): string {
  if (uuid) {
    return `https://ucarecdn.com/${uuid}/-/scale_crop/${scale}/smart/-/quality/smart/-/progressive/yes/`;
  }

  return 'https://ucarecdn.com/b55ef792-691b-4afe-8205-9b7490074254/nothumbnail.svg';
}
