// ANCHOR Base
import { DarkTheme } from 'baseui';

// ANCHOR Theme
import { DARK_MUTED } from './darkmuted';
import { MUTED } from './muted';

export const INPUT = {
  inputFill: DARK_MUTED.darkmuted400,
  inputFillError: DarkTheme.colors.negative700,
  inputFillDisabled: DARK_MUTED.darkmuted500,
  inputFillActive: DARK_MUTED.darkmuted300,
  inputFillPositive: DarkTheme.colors.positive700,
  inputTextDisabled: DARK_MUTED.darkmuted300,
  inputBorderError: DarkTheme.colors.negative,
  inputBorderPositive: DarkTheme.colors.positive,
  inputEnhancerFill: DARK_MUTED.darkmuted300,
  inputEnhancerFillDisabled: MUTED.muted100,
  inputEnhancerTextDisabled: DARK_MUTED.darkmuted300,
};
