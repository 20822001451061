// ANCHOR Base
import { DarkTheme } from 'baseui';

// ANCHOR Themes
import { DARK_MUTED } from './darkmuted';
import { PRIMARY } from './primary';

export const BUTTON = {
  // ANCHOR Primary
  buttonPrimaryFill: PRIMARY.primary,
  buttonPrimaryText: PRIMARY.primary50,
  buttonPrimaryHover: PRIMARY.primary300,
  buttonPrimaryActive: PRIMARY.primary200,

  // ANCHOR Secondary
  buttonSecondaryFill: DARK_MUTED.darkmuted300,
  buttonSecondaryText: PRIMARY.primary50,
  buttonSecondaryHover: DARK_MUTED.darkmuted200,
  buttonSecondaryActive: DARK_MUTED.darkmuted100,

  // ANCHOR Tertiary
  buttonTertiaryFill: DarkTheme.colors.buttonTertiaryFill,
  buttonTertiaryText: PRIMARY.primary50,
  buttonTertiaryHover: DARK_MUTED.darkmuted300,
  buttonTertiaryActive: DARK_MUTED.darkmuted200,
  buttonTertiarySelectedFill: DARK_MUTED.darkmuted200,
  buttonTertiarySelectedText: PRIMARY.primary50,

  // ANCHOR Minimal
  buttonMinimalFill: DarkTheme.colors.buttonTertiaryFill,
  buttonMinimalText: PRIMARY.primary50,
  buttonMinimalHover: DARK_MUTED.darkmuted300,
  buttonMinimalActive: DARK_MUTED.darkmuted200,
  buttonDisabledFill: DARK_MUTED.darkmuted400,
  buttonDisabledText: DARK_MUTED.darkmuted100,
};
