import { DARK_MUTED } from './darkmuted';
import { MUTED } from './muted';
import { PRIMARY } from './primary';

export const TAG = {
  tagNeutralLightBackground: DARK_MUTED.darkmuted400,
  tagNeutralLightHover: DARK_MUTED.darkmuted300,
  tagNeutralLightActive: DARK_MUTED.darkmuted200,
  tagNeutralLightDisabled: DARK_MUTED.darkmuted500,
  tagNeutralLightFont: MUTED.muted50,
  tagNeutralLightFontHover: MUTED.muted100,
  tagNeutralSolidBackground: MUTED.muted50,
  tagNeutralSolidHover: DARK_MUTED.darkmuted400,
  tagNeutralSolidActive: DARK_MUTED.darkmuted200,
  tagNeutralSolidDisabled: DARK_MUTED.darkmuted500,
  tagNeutralSolidFont: '#FFFFFF',
  tagNeutralSolidFontHover: MUTED.muted50,
  tagNeutralOutlinedBackground: MUTED.muted50,
  tagNeutralOutlinedHover: '#545454',
  tagNeutralOutlinedActive: MUTED.muted50,
  tagNeutralOutlinedDisabled: DARK_MUTED.darkmuted500,
  tagNeutralOutlinedFont: MUTED.muted50,
  tagNeutralOutlinedFontHover: DARK_MUTED.darkmuted500,
  tagNeutralFontDisabled: '#CBCBCB',
  tagPrimarySolidBackground: PRIMARY.primary400,
  tagPrimarySolidHover: DARK_MUTED.darkmuted400,
  tagPrimarySolidActive: DARK_MUTED.darkmuted200,
  tagPrimarySolidDisabled: DARK_MUTED.darkmuted500,
  tagPrimarySolidFont: DARK_MUTED.darkmuted500,
  tagPrimarySolidFontHover: MUTED.muted50,
  tagPrimaryLightBackground: DARK_MUTED.darkmuted500,
  tagPrimaryLightHover: DARK_MUTED.darkmuted400,
  tagPrimaryLightActive: DARK_MUTED.darkmuted400,
  tagPrimaryLightDisabled: DARK_MUTED.darkmuted500,
  tagPrimaryLightFont: '#757575',
  tagPrimaryLightFontHover: '#757575',
  tagPrimaryOutlinedBackground: PRIMARY.primary400,
  tagPrimaryOutlinedHover: MUTED.muted50,
  tagPrimaryOutlinedActive: '#545454',
  tagPrimaryOutlinedDisabled: DARK_MUTED.darkmuted500,
  tagPrimaryOutlinedFont: PRIMARY.primary400,
  tagPrimaryOutlinedFontHover: DARK_MUTED.darkmuted500,
  tagPrimaryFontDisabled: '#AFAFAF',
};
