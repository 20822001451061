export const NEGATIVE = {
  negative50: '#FDF0EF',
  negative100: '#FADBD7',
  negative200: '#F4AFA7',
  negative300: '#EB7567',
  negative400: '#FF4D4D',
  negative500: '#AE372A',
  negative600: '#892C21',
  negative700: '#5C1D16',
};
