export enum API_TYPE {
  Default = 'DEFAULT',
  OnDemand = 'ON_DEMAND',
  Media = 'MEDIA',
  Meeting = 'MEETING',
  Notification = 'NOTIFICATION',
  Auth = 'AUTH',
  Base = 'BASE',
  Payment = 'PAYMENT',
}

export const API_LOOKUP = {
  [API_TYPE.Default]: process.env.API ?? '',
  [API_TYPE.OnDemand]: process.env.API_ON_DEMAND ?? '',
  [API_TYPE.Media]: process.env.API_MEDIA ?? '',
  [API_TYPE.Meeting]: process.env.API_MEETING ?? '',
  [API_TYPE.Auth]: process.env.API_AUTH ?? '',
  [API_TYPE.Notification]: process.env.API_NOTIFICATION ?? '',
  [API_TYPE.Base]: '',
  [API_TYPE.Payment]: 'https://payment-service.lyon.com.ph',
};

export function apiFetch(
  url: string,
  config?: RequestInit,
  type: API_TYPE = API_TYPE.Default,
) {
  const API = API_LOOKUP[type];
  return fetch(`${API}${url}`, {
    ...config,
    mode: 'cors',
  });
}

export function GET(
  url: string,
  config?: RequestInit,
  type?: API_TYPE,
) {
  return apiFetch(
    url,
    {
      ...config,
      method: 'GET',
    },
    type,
  );
}

export function POST(
  url: string,
  data?: any,
  config?: RequestInit,
  type?: API_TYPE,
) {
  return apiFetch(
    url,
    {
      ...config,
      method: 'POST',
      body: JSON.stringify(data),
    },
    type,
  );
}

export function PUT(
  url: string,
  data?: any,
  config?: RequestInit,
  type?: API_TYPE,
) {
  return apiFetch(
    url,
    {
      ...config,
      method: 'PUT',
      body: JSON.stringify(data),
    },
    type,
  );
}

export function PATCH(
  url: string,
  data?: any,
  config?: RequestInit,
  type?: API_TYPE,
) {
  return apiFetch(
    url,
    {
      ...config,
      method: 'PATCH',
      body: JSON.stringify(data),
    },
    type,
  );
}
