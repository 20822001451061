export const BORDER = {
  border100: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'hsla(0, 0%, 100%, 0.04)',
  },
  border200: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'hsla(0, 0%, 100%, 0.08)',
  },
  border300: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'hsla(0, 0%, 100%, 0.12)',
  },
  border400: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'hsla(0, 0%, 100%, 0.16)',
  },
  border500: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'hsla(0, 0%, 100%, 0.2)',
  },
  border600: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'hsla(0, 0%, 100%, 0.24)',
  },
};
