// ANCHOR Firebase
import firebase from 'firebase';

// ANCHOR Graph State
import { node } from 'graph-state';
import {
  useGraphNodeValue,
} from 'react-graph-state';

// ANCHOR Dependencies
import { firebaseAuthNode } from '../firebase-auth-node';

export const authStateNode = node<undefined | null | firebase.User>({
  key: 'authState',
  get: ({ get, mutateSelf, subscription }) => {
    get(firebaseAuthNode).then((auth) => {
      if (auth) {
        subscription(
          () => auth.onAuthStateChanged(mutateSelf),
        );
      }
    }, () => {
      // err
    });

    return undefined;
  },
});

export function useAuthState() {
  return useGraphNodeValue(authStateNode);
}
