// ANCHOR Firebase
import firebase from 'firebase';

// ANCHOR Graph State
import { node, resource } from 'graph-state';
import { useGraphNodeValue } from 'react-graph-state';

// ANCHOR Dependencies
import { firebaseAppNode } from './firebase-app-node';

export const firebaseAuthNode = node<Promise<firebase.auth.Auth | undefined>>({
  key: 'firebaseAuth',
  get: async ({ get }) => {
    const app = await get(firebaseAppNode);

    if (app) {
      await import('firebase/auth');

      return app.auth();
    }
    return undefined;
  },
});

export const firebaseAuthResource = resource(firebaseAuthNode);

export function useFirebaseAuth() {
  return useGraphNodeValue(firebaseAuthResource);
}
