// ANCHOR React
import * as React from 'react';

interface IValue<T> {
  value: T;
}

export function useConstant<T>(fn: () => T): T {
  const ref = React.useRef<IValue<T>>();

  if (!ref.current) {
    ref.current = {
      value: fn(),
    };
  }

  return ref.current.value;
}
