// ANCHOR Graph State
import {
  useGraphNodeValue,
} from 'react-graph-state';
import {
  swr,
} from 'swr-graph-state';

// ANCHOR API
import { getAdminCheck } from '@lyon/utils/api/user';

// ANCHOR Dependencies
import { authStateNode } from './auth-state-node';

export const authAdmin = swr<boolean>({
  key: 'authAdmin',
  setup: (context) => {
    const user = context.get(authStateNode);

    return async (): Promise<boolean> => {
      if (user) {
        return getAdminCheck({
          token: (await user.getIdToken()),
          userId: user.uid,
        });
      }
      return false;
    };
  },
  freshAge: 0,
  revalidateOnFocus: true,
  revalidateOnVisibility: true,
  staleAge: 1000 * 60 * 60,
});

export function useAuthAdmin() {
  const value = useGraphNodeValue(authAdmin.resource);

  if (value.status === 'pending') {
    return undefined;
  }

  if (value.status === 'failure') {
    throw value.data;
  }

  return value.data;
}
