/* eslint-disable no-console */
// ANCHOR Next
import App, { AppContext } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';

// ANCHOR Zipy
import zipy from 'zipyai';

// ANCHOR React
import { useEffect } from 'react';

// ANCHOR Next User Agent
import { useUserAgent as parseUserAgent, UserAgent } from 'next-useragent';

// ANCHOR Models
import { GraphDomain } from 'react-graph-state';
import { useAuthState } from '@lyon/nodes/firebase/auth/auth-state-node';
import { useUserProviderAccount } from '@lyon/nodes/navbar/user-provider-account-node';
import { useAuthAdmin } from '@lyon/nodes/firebase/auth/auth-admin-node';

// ANCHOR Utils
import { ucarecdn } from '@lyon/utils/ucarecdn';
import { page, identify, group } from '@lyon/utils/segment';
import { renderSegmentSnipper } from '@lyon/utils/segment/renderSegmentSnipper';

// ANCHOR Component
import { GlobalProvider } from '@lyon/components/GlobalProvider';
import { ServiceWorker } from '@lyon/components/ServiceWorker';

// ANCHOR CSS
import 'react-quill/dist/quill.snow.css';
import 'normalize.css/normalize.css';
import '../public/styles/parsed-link.css';
import '../css/quilljs.css';
import '../css/tailwind-quill.css';
import '../css/blurhash.css';
import '../public/styles/utility.css';
import '../css/ibm.css';

interface AppProps {
  ua: UserAgent;
}

function SegmentAnalytics({ ua }: AppProps) {
  const user = useAuthState();
  const router = useRouter();
  const trainerAccounts = useUserProviderAccount();
  const isAdmin = useAuthAdmin();

  useEffect(() => {
    zipy.init('7d164d1b')
      .catch(console.error);
  }, []);

  useEffect(() => {
    page(router.asPath, isAdmin);
  }, [router.asPath, isAdmin]);

  useEffect(() => {
    if (trainerAccounts.status === 'success') {
      trainerAccounts.data?.forEach((account) => {
        group(account.id, {
          id: account.id,
          name: account.name,
          website: `https://lyon.com.ph/provider/${account.id}`,
          email: account.email,
          description: account.description,
          createdAt: account.createdAt,
          phone: account.contactNumber,
          address: account.address,
          avatar: account.displayPhotoUuid && ucarecdn(account.displayPhotoUuid),
          employees: account.managingUserCount,
          students: account.studentCount,
        }, isAdmin);
      });
    }
  }, [isAdmin, trainerAccounts.data, trainerAccounts.status]);

  useEffect(() => {
    if (trainerAccounts.status === 'success' && typeof user !== 'undefined') {
      if (user) {
        identify(user.uid, {
          name: user.displayName,
          email: user.email,
          verified: user.emailVerified,
          createdAt: user.metadata.creationTime,
          lastSignInAt: user.metadata.lastSignInTime,
          id: user.uid,
          phone: user.phoneNumber,
          avatar: user.photoURL,
          source: ua.source,
          trainingSpaces: trainerAccounts.data?.length ?? 0,
        }, isAdmin);

        zipy.identify(user.uid, {
          firstName: user.displayName,
          customerName: trainerAccounts.data?.[0]?.id ?? 'lyon',
          email: user.email,
          avatar: user.photoURL,
          phone: user.phoneNumber,
        });
      } else {
        window.analytics.identify({
          source: ua.source,
        });
      }
    }
  }, [
    isAdmin, trainerAccounts.data, trainerAccounts.data?.length,
    trainerAccounts.status, ua.browser, ua.source, user,
  ]);

  return null;
}

// NOTE Next App requires this to be exported by default
// eslint-disable-next-line import/no-default-export
export default class LyonApp extends App<AppProps> {
  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  //
  public static async getInitialProps(appContext: AppContext) {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await App.getInitialProps(appContext);
    const ua = parseUserAgent(appContext.ctx.req?.headers['user-agent'] ?? '');

    return { ...appProps, ua };
  }

  public render() {
    const {
      Component, pageProps, ua,
    } = this.props;

    return (
      <>
        <GraphDomain>
          <GlobalProvider ua={ua}>
            <ServiceWorker>
              <Component {...pageProps} />
              <SegmentAnalytics ua={ua} />
            </ServiceWorker>
          </GlobalProvider>
        </GraphDomain>
        <Script src="/scripts/datadog-rum.js" strategy="lazyOnload" />
        <Script src="/scripts/intercom-settings.js" strategy="lazyOnload" />
        <Script src="/scripts/intercom-widget.js" strategy="lazyOnload" />
        <Script
          id="__SEGMENT"
          // FIXME the intended behavior is "beforeInteractive" however
          // it is currently broken
          // strategy="beforeInteractive"
        >
          {renderSegmentSnipper()}
        </Script>
      </>
    );
  }
}
