// ANCHOR React
import React from 'react';

// ANCHOR Hooks
import { useSentryExceptionHandler } from '@lyon/utils/hooks/useSentryExceptionHandler';

import { useServiceWorker } from '@lyon/nodes/service-worker';

interface IProps {
  children: React.ReactNode;
}

export function ServiceWorker({ children }: IProps) {
  const catchException = useSentryExceptionHandler();
  const result = useServiceWorker();

  if (result.status === 'failure') {
    catchException(result.data);
  }

  return (
    <>
      { children }
    </>
  );
}
