import { DARK_MUTED } from './darkmuted';
import { MUTED } from './muted';

export const MENU = {
  menuFill: DARK_MUTED.darkmuted400,
  menuFillHover: DARK_MUTED.darkmuted500,
  menuFontDefault: MUTED.muted200,
  menuFontDisabled: MUTED.muted500,
  menuFontHighlighted: MUTED.muted100,
  menuFontSelected: MUTED.muted50,
};
