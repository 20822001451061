// ANCHOR React
import React, { FC, ReactNode } from 'react';

// ANCHOR Next
import dynamic from 'next/dynamic';

// ANCHOR React DND
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// ANCHOR Toast
import { Toaster } from 'react-hot-toast';

// ANCHOR Styletron
import { Provider as StyletronProvider } from 'styletron-react';

// ANCHOR User Agent
import { UserAgent } from 'next-useragent';

// ANCHOR Graph State
import { useUserAgentHydrate } from '@lyon/nodes/global/user-agent-node';

// ANCHOR Utilities
import { debug, styletron } from '@lyon/utils/styletron';
// import { isWebsiteRestricted } from '@lyon/utils/is-website-restricted';

// ANCHOR Components
import { LyonThemeProvider } from './LyonThemeProvider';
import { OnlineTracker } from './OnlineTracker';

interface IGlobalProviderProps {
  children?: ReactNode;
  ua: UserAgent;
}

// ANCHOR Providers
const RootProvider = dynamic(
  async () => {
    const mod = await import('./utils/Providers');
    return mod.RootProvider;
  },
);

// const LyonUnsupported = dynamic(
//   async () => {
//     const mod = await import('./unsupported');
//     return mod.LyonUnsupported;
//   },
// );

const toasterStyle = styletron.renderStyle({
  fontFamily: '"IBM Plex Sans", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSize: '14px',
  fontWeight: 'normal',
  lineHeight: '20px',
});

const InnerGlobalProvider: FC = ({ children }) => (
  // Commented for now
  // if (isWebsiteRestricted(ua)) {
  //   return <LyonUnsupported />;
  // }

  <RootProvider>
    <OnlineTracker />
    <DndProvider backend={HTML5Backend}>
      {children}
    </DndProvider>
    <Toaster
      position="bottom-left"
      toastOptions={{
        className: toasterStyle,
      }}
    />
  </RootProvider>
);

export function GlobalProvider({ children, ua }: IGlobalProviderProps) {
  useUserAgentHydrate(ua);

  return (
    <StyletronProvider
      value={styletron}
      debug={debug}
      debugAfterHydration
    >
      <LyonThemeProvider>
        <InnerGlobalProvider>
          { children }
        </InnerGlobalProvider>
      </LyonThemeProvider>
    </StyletronProvider>
  );
}
