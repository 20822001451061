// ANCHOR Firebase
import firebase from 'firebase';

// ANCHOR Graph State
import { node } from 'graph-state';

// ANCHOR Import Scheduler
import { importForInteractive } from 'import-scheduler';

// ANCHOR Utils
import { IS_SERVER } from '@lyon/utils/status';

// ANCHOR Credentials
const CREDENTIALS = {
  apiKey: process.env.FIREBASE_API_KEY,
  projectId: process.env.FIREBASE_PROJECT_ID,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const firebaseAppNode = node<Promise<firebase.app.App | undefined>>({
  key: 'firebaseApp',
  get: async () => {
    if (IS_SERVER) {
      return undefined;
    }
    await importForInteractive();
    const firebaseApp = (await import('firebase/app')).default;

    if (firebaseApp.apps.length) {
      // If there are instances, return the current app.
      return firebaseApp.app();
    }

    // Other wise, instanciate the app.
    return firebaseApp.initializeApp(CREDENTIALS);
  },
});
