/* eslint-disable @typescript-eslint/no-unsafe-call */
// ANCHOR Payloads
import {
  ISignUpPayload,
  IGetUserByIdPayload,
  IGetUserByIdsPayload,
} from './payloads/user';

// ANCHOR Results
import { IProviderResult } from './results/provider';
import { IUserResult } from './results/user';
import { ISpeakerResult } from './results/speaker';
import { IBasicUserResult } from './results/profile';

export async function signupUser(payload: ISignUpPayload) {
  const { sdk } = await import('../axios/graphql');

  const { user } = await sdk().CreateUser({
    id: payload.id,
    email: payload.email.toLowerCase(),
    displayName: payload.name,
    displayPhotoUuid: payload.displayPhotoUuid,
  });

  return user;
}

export async function getUserProviderAccount(
  { userId, token }: IGetUserByIdPayload,
): Promise<IProviderResult[]> {
  const { sdk } = await import('../axios/graphql');

  const { provider } = await sdk(token).GetUserProviderAccountById({
    id: userId,
  });

  return Promise.all(
    provider.map(async (item) => {
      const { courseStudentAggregate } = await sdk(token).GetProviderStudentCount({
        id: item.provider.id,
      });

      return ({
        ...item.provider,
        managingUserCount: item.provider.managingUserAggregate.aggregate?.count,
        studentCount: courseStudentAggregate.aggregate?.count,
      }) as unknown as IProviderResult;
    }),
  );
}

export async function getAdminCheck(
  { userId, token }: IGetUserByIdPayload,
): Promise<boolean> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).GetUserAdminById({
    id: userId,
  });

  return !!response.user?.isAdmin;
}

export async function getUserByIds(
  { token, userIds }: IGetUserByIdsPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const { user } = await sdk(token).GetBasicUserDetailsByIDs({
    ids: userIds,
  });

  return user as IUserResult[];
}

export async function getUserByEmail(
  email: string,
): Promise<IBasicUserResult | undefined> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk().GetBasicUserDetailsByEmail({
    email,
  });

  if (response.user.length) {
    return response.user[0] as IBasicUserResult;
  }

  return undefined;
}

export async function getUserSpeaker(
  { userId, token }: IGetUserByIdPayload,
): Promise<ISpeakerResult | undefined> {
  const { sdk } = await import('../axios/graphql');
  const { speaker } = await sdk(token).GetCourseSpeakerByUserId({
    id: userId,
  });

  if (speaker.length) {
    return speaker[0] as ISpeakerResult;
  }

  return undefined;
}
