import { IRegisterNotificationPayload, IViewNotificationPayload, IUserRequireSignIn } from './payloads/notification';
import { POST, PATCH, API_TYPE } from '../axios/methods';

export async function registerNotification(
  { token, deviceToken, userId }: IRegisterNotificationPayload,
) {
  return POST('/notification', {
    deviceToken,
    userId,
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }, API_TYPE.Notification);
}

export async function viewNotification(
  { token, notificationId, userId }: IViewNotificationPayload,
) {
  return PATCH(`/notification/view/${notificationId}`, { userId }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    keepalive: true,
  }, API_TYPE.Notification);
}

export async function viewNotifications(
  { token, userId }: IUserRequireSignIn,
) {
  return PATCH('/notification/open', { userId }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }, API_TYPE.Notification);
}
