export const MUTED = {
  muted: '#615D6F',
  muted50: '#EFEEF1',
  muted100: '#D4D3DA',
  muted200: '#B9B7B3',
  muted300: '#9E9BAB',
  muted400: '#898599',
  muted500: '#746F85',
  muted600: '#6A667A',
  muted700: '#615D6F',
};
