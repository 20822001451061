export const PRIMARY = {
  primary: '#5B36C9',
  primary50: '#F3F0FF',
  primary100: '#BBA8F5',
  primary200: '#8164D8',
  primary300: '#6341C8',
  primary400: '#5B36C9',
  primary500: '#4D3399',
  primary600: '#271F47',
  primary700: '#16141F',
};
