// ANCHOR Firebase
import firebase from 'firebase';

// ANCHOR Graph State
import { node, resource } from 'graph-state';
import { useGraphNodeValue } from 'react-graph-state';

// ANCHOR Dependencies
import { firebaseAppNode } from './firebase-app-node';

export const firebaseMessagingNode = (
  node<Promise<firebase.messaging.Messaging | undefined>>({
    key: 'firebaseMessaging',
    get: async ({ get }) => {
      const app = await get(firebaseAppNode);

      if (app) {
        await import('firebase/messaging');

        return app.messaging();
      }
      return undefined;
    },
  })
);

export const firebaseMessagingResource = resource(firebaseMessagingNode);

export function useFirebaseMessaging() {
  return useGraphNodeValue(firebaseMessagingResource);
}
