// ANCHOR Base
import { DarkTheme } from 'baseui';

// ANCHOR Theme
import { BREAKPOINTS } from './breakpoints';
import { BACKGROUND } from './colors/background';
import { BUTTON } from './colors/button';
import { DARK_MUTED } from './colors/darkmuted';
import { FOREGROUND } from './colors/foreground';
import { HEADER_NAVIGATION } from './colors/header-navigation';
import { INPUT } from './colors/input';
import { LINK } from './colors/link';
import { MENU } from './colors/menu';
import { MUTED } from './colors/muted';
import { NOTIFICATION } from './colors/notification';
import { PRIMARY } from './colors/primary';
import { TAG } from './colors/tag';
import { TYPOGRAPHY } from './typography';
import { SHADOWS } from './colors/shadows';
import { MONO } from './colors/mono';
import { RATING } from './colors/rating';
import { NEGATIVE } from './colors/negative';
import { POSITIVE } from './colors/positive';
import { BORDER } from './border';

export const THEME = {
  colors: {
    ...DarkTheme.colors,
    // ANCHOR Color definitions
    ...PRIMARY,
    ...MONO,
    ...MUTED,
    ...DARK_MUTED,
    ...NEGATIVE,
    ...POSITIVE,

    // ANCHOR Element property definitions
    ...BACKGROUND,
    ...BUTTON,
    ...FOREGROUND,
    ...HEADER_NAVIGATION,
    ...INPUT,
    ...LINK,
    ...MENU,
    ...NOTIFICATION,
    ...TAG,
    ...RATING,

    // ANCHOR misc definitions
    colorPrimary: PRIMARY.primary50,
    colorSecondary: PRIMARY.primary,
  },
  borders: {
    ...BORDER,
  },
  lighting: {
    ...DarkTheme.lighting,
    ...SHADOWS,
  },
  media: BREAKPOINTS,
  typography: TYPOGRAPHY,
  name: 'lyon-theme',
};
