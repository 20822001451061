// ANCHOR Graph State
import {
  node,
} from 'graph-state';
import {
  useGraphNodeHydrate,
  useGraphNodeValue,
} from 'react-graph-state';

// ANCHOR User Agent
import {
  UserAgent,
} from 'next-useragent';

export const userAgentNode = node({
  key: 'userAgent',
  get: {} as UserAgent,
});

export function useUserAgent() {
  return useGraphNodeValue(userAgentNode);
}

export function useUserAgentHydrate(ua: UserAgent) {
  return useGraphNodeHydrate(userAgentNode, ua);
}
