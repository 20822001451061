// ANCHOR Graph State
import { node, resource } from 'graph-state';
import { useGraphNodeValue } from 'react-graph-state';

// ANCHOR SWR
import { mutate as mutateSWR } from 'swr';
import { mutate } from 'swr-graph-state';

// ANCHOR Utils
import { IS_SERVER } from '@lyon/utils/status';

// ANCHOR API
import { registerNotification } from '@lyon/utils/api/notification';
import { API_LOOKUP, API_TYPE } from '@lyon/utils/axios/methods';

// ANCHOR Dependencies
import { firebaseMessagingNode } from '../firebase/firebase-messaging-node';
import { authStateNode } from '../firebase/auth/auth-state-node';

interface WorkboxMessagePayload {
  cacheName: string;
  updatedURL: string;
}

interface WorkboxMessage {
  payload: WorkboxMessagePayload;
  meta: string;
}

type ServiceWorkerRegistrationNode =
  Promise<ServiceWorkerRegistration | undefined>;

const serviceWorkerRegistrationNode = node<ServiceWorkerRegistrationNode>({
  key: 'serviceWorkerRegistration',
  get: async ({ subscription }) => {
    if (!IS_SERVER && 'serviceWorker' in navigator) {
      const worker = navigator.serviceWorker.register(`${window.location.origin}/sw.js`);

      subscription(() => {
        const onMessage = (event: MessageEvent<WorkboxMessage>) => {
          // Optional: ensure the message came from workbox-broadcast-update
          if (event.data.meta === 'workbox-broadcast-update') {
            const { cacheName, updatedURL } = event.data.payload;

            // Do something with cacheName and updatedUrl.
            // For example, get the cached content and update
            // the content on the page.
            caches.open(cacheName)
              .then(async (cache) => {
                const response = await cache.match(updatedURL);

                if (response) {
                  const getRoute = () => {
                    if (updatedURL.startsWith(API_LOOKUP[API_TYPE.OnDemand])) {
                      return updatedURL.replace(API_LOOKUP[API_TYPE.OnDemand], '');
                    }
                    if (updatedURL.startsWith(API_LOOKUP[API_TYPE.Default])) {
                      return updatedURL.replace(API_LOOKUP[API_TYPE.Default], '');
                    }
                    return updatedURL;
                  };
                  const extractRoute = getRoute();

                  const data = await response.json();
                  mutate(extractRoute, {
                    status: 'success',
                    data,
                  }, false);
                  await mutateSWR(extractRoute, data, false);
                }
              })
              .catch((err) => {
                console.error(err);
              });
          }
        };

        navigator.serviceWorker.addEventListener('message', onMessage);

        return () => {
          navigator.serviceWorker.removeEventListener('message', onMessage);
        };
      });

      return worker;
    }
    return undefined;
  },
});

const VAPID_KEY = process.env.FIREBASE_VAPID_KEY ?? '';

const messagingRegistrationNode = node<Promise<string | undefined>>({
  key: 'messagingRegistration',
  get: async ({ get }) => {
    const serviceWorkerRegistration = await get(serviceWorkerRegistrationNode);

    if (serviceWorkerRegistration) {
      const messaging = await get(firebaseMessagingNode);
      if (messaging) {
        try {
          const token = await messaging.getToken({
            vapidKey: VAPID_KEY,
            serviceWorkerRegistration,
          });
          return token;
        } catch (err) {
          console.error(err);
        }
      }
    }

    return undefined;
  },
});

const deviceTokenRegistrationNode = node<Promise<Response | undefined>>({
  key: 'deviceTokenRegistration',
  get: async ({ get }) => {
    // Preload serverice worker registration
    await get(serviceWorkerRegistrationNode);

    const user = get(authStateNode);

    if (user) {
      const deviceToken = await get(messagingRegistrationNode);
      if (deviceToken) {
        return registerNotification({
          token: await user.getIdToken(),
          userId: user.uid,
          deviceToken,
        });
      }
    }
    return undefined;
  },
});

const deviceTokenRegistrationResource = resource(
  deviceTokenRegistrationNode,
);

export function useServiceWorker() {
  return useGraphNodeValue(deviceTokenRegistrationResource);
}
