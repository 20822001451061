export const DARK_MUTED = {
  darkmuted: '#0F0E11',
  darkmuted50: '#585464',
  darkmuted100: '#4E4A59',
  darkmuted200: '#44414E',
  darkmuted300: '#383540',
  darkmuted400: '#2E2C35',
  darkmuted500: '#24222A',
  darkmuted550: '#1B1A1E',
  darkmuted600: '#161519',
  darkmuted650: '#111111',
  darkmuted700: '#0F0F10',
};
