export const POSITIVE = {
  positive: '#21A453',
  positive50: '#F0FAF3',
  positive100: '#CFF3DD',
  positive200: '#9EE2B8',
  positive300: '#43BF75',
  positive400: '#21A453',
  positive450: '#41D8A2',
  positive500: '#09863D',
  positive600: '#156A36',
  positive700: '#0D4020',
};
